.fact-heading {
    font-size: 1.75rem;
    letter-spacing: 1px;

}

.fact-text {
  font-size: 1.5rem;
  width: 75%;
  text-align: center;
  font-weight: 100;
  letter-spacing: 1px;
}


.fact-icon {
    font-size: 5rem;
    color: #5C9C55;
    margin-bottom: 2rem;
}