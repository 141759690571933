.contact-title {
  font-size: 5rem;
  font-weight: 400;
  font-family: "Jomolhari", serif;
  border-bottom: 4px solid black;
}

.contact-row {
  display: flex;
  margin: 5rem;
}

.contact-col {
  width: 50vw;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.left p {
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.bold-label {
  font-weight: 600;
}

@media only screen and (max-width: 1050px) {
  .contact-row {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0;
  }

  .contact-col {
    width: initial;
  }

  .left h2, .left p {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 875px) {
  .left h2, .left p{
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .contact-title {
    font-size: 4rem;
  }
}