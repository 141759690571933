.pagination {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  font-size: 1.5rem;
}

.page-item:hover {
  cursor: pointer;
}
