.green-btn {
  background-color: rgb(128, 190, 121);
  box-shadow: 0 1rem 1rem -10px rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 3px;
  padding: 1rem 2rem;
  transform: translateY(2px);
  transition: all 0.2s;
  cursor: pointer;
  text-align: center;
  font-size: 1.75rem;
  margin: auto;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: transparent;
}
.green-btn:hover {
  background-color: rgb(91, 179, 81);
  transform: translateY(-2px);
  box-shadow: 0 0.75rem 2rem rgba(0, 0, 0, 0.4);
}
.green-btn:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
}

.green-btn:focus {
  outline: 0;
}
