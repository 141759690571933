/* RESET FILE */

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  /* Setting the base font size to 62.5%
    this is 62.5% of the browsers default font size - 16px
    thus making our base font size 10px and making it easy to use rem for all children measurements
    This is a good practice to do incase users change the default font size. Very flexible */
}

body {
  box-sizing: border-box;
  font-family: sans-serif;
  overflow-x: hidden;
  position: relative;
  max-width: 100vw;
  margin-top: 8rem;
  max-width: 100vw;
}

.App {
  overflow-x: hidden;
}

@media only screen and (max-width: 475px) {
  body {
    margin-top: 6.6rem;
  }
}

@media only screen and (max-width: 390px) {
  body {
    margin-top: 5rem;
  }
}

