.cart-item {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 2rem;
    margin: auto;
    border-bottom: 1px solid grey;
    transition: all .2s;
}

.cart-item:hover {
    background-color: #f5f5f5;
}

.cart-item-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 1rem;
}

.cart-item-img {
    height: 10rem;
    width: 10rem;
    margin-right: 1rem;
}

@media only screen and (max-width: 975px) {
    .cart-item {
        min-width: 50vw;
    }
}
@media only screen and (max-width: 800px) {
    .cart-item {
        min-width: 60vw;
    }
}
@media only screen and (max-width: 650px) {
    .cart-item {
        min-width: 75vw;
    }
}

@media only screen and (max-width: 475px) {
    .cart-item {
        align-items: center;
        width: 100%;
    }
    .cart-item-img {
        margin-left: 1rem;
        height: 9rem;
    }
}
