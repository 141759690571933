/* For collapsable nav menu that takes up 100% of the view width, 
  remove padding from the main nav element. This will cause a 100vw collapsable div to be
  pushed off screen
*/

/* ---------- Navbar Styling ----------- */
.navbar {
  background-color: #5c9c55 !important;
  padding: 1rem;
  font-size: 1.5rem;
  font-size: 300%;
  font-family: 'Montserrat', sans-serif;
}

.navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: center;
}


#myNavbar {
  margin: 0 auto;
  padding: 0;
}

/* ---------- Navbar children styles ----------- */

.navbar-brand {
  margin: 0;
  color: #03293c !important;
  font-size: 100%;
  width: 50vw;
  text-align: center;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 300;
  cursor: pointer;
}


.navbar-toggler {
  font-size: 2rem;
  cursor: pointer;
  margin-right: 2rem !important;
}

.navbar-toggler:focus {
    outline: none;
}   

.navbar-brand-text {
  cursor: pointer;
}

.brand-text-highlight {
  font-weight: 700;
}

.nav-item {
  letter-spacing: 1px;
}


.hover {
  padding-right: .5rem;
  padding-left: .5rem;
  color: white;
  font-size: 1.75rem;
  font-weight: 400;
  margin: 0 1rem -2px 1rem;
  cursor: pointer;
  transition: all .1s;
  text-decoration: none;
}

.hover:hover {
  color: white;
  font-weight: 700;
  text-decoration: none;
}

ul.navbar-nav {
  width: 50vw;
}

@media only screen and (max-width: 991px) {
  ul.navbar-nav {
    background-color: #5c9c55 !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100vw;
    max-width: 100vw;
  }
  .nav-item {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .navbar-brand {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 475px) {
  .navbar-brand {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 390px) {
  .navbar-brand {
    font-size: 3rem;
  }
}