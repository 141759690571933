.image-ps-mobile {
    height: auto;
    width: 70%;
    margin-top: 4rem;
    border-radius: 3px;
}

.data-col-mb {
    padding: 0;
    width: 70vw;
    margin-left: 15%;
    justify-content: start;
}

.image-col-mb {
    min-height: unset !important;
}


.green-btn-inverse-mb {
    background-color: transparent;
    color: rgb(91, 179, 81);
    border: 1px solid rgb(91, 179, 81);
    margin-bottom: 2rem;
}

.green-btn-inverse-mb:hover {
    color: white;
}

.ps-add:focus {
    outline: none;
}