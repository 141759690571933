.products-page {
  padding: 3rem;
  min-height: 70vh;
}

.filterInput {
  padding: 0.75rem 1rem;
  font-size: 1.5rem;
  border: 2px solid #f5f5f5;
  background-color: rgb(247, 250, 252);
  color: var(--gray-800);
  border-radius: 10px;
  margin-top: 0.5rem;
  display: block;
  margin: auto;
  width: 75%;
  margin-bottom: 3rem;
}

.filterInput:focus {
  outline: none;
}

.category-list {
  width: 75%;
  margin: auto;
  padding-left: 1rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: #9f9f9f;
  font-weight: 100;
  margin-bottom: 1rem;
}

.active-category {
  font-weight: 400;
  color: #5b9c55;
}

.category-link {
  cursor: pointer;
}

.category-link:hover {
  color: #5b9c55;
  font-weight: 400;
}
