.cart-page {
  padding: 3rem;
  min-height: 81vh;
  position: relative;
}

.cart-col-wrapper {
  margin-top: 5rem;
  display: flex;
  min-height: 60vh;
}

.cart-col {
  width: 50vw;
  border-right: 2px solid green;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 60vh;
  overflow: scroll;
  justify-content: center;
  padding: 0 3rem;
}

.cart-items-list {
  list-style: none;
}

.free-shipping {
  text-decoration: line-through;
  color: rgba(205, 205, 205, 0.97);
}

.even {
  justify-content: space-between;
  align-items: flex-start;
}

.col-left-top {
  width: 100%;
  text-align: center;
}

.cart-item-li {
  letter-spacing: 1px;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.col-left-bottom {
  background-color: #f5f5f5;
  border-radius: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
}

.cart-col:last-child {
  border-right: none;
}

.cart-col-right {
  max-height: 60vh;
  overflow: scroll;
  width: 50vw;
  min-width: 35rem;
}

.successToast {
  font-size: 1.5rem;
}

.failureToast {
  font-size: 1.5rem;
}

.failBackground {
  background: red !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: #fff;
  padding: 2rem;
}

.paper:focus {
  outline: 0;
}

.transition-modal-description {
  font-size: 1.25rem;
}

@media only screen and (max-width: 975px) {
  .cart-col-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .cart-col-right {
    margin-bottom: 3rem;
    overflow: initial;
    max-height: initial;
  }

  .cart-col-small {
    margin-bottom: 3rem;
    overflow: initial;
    max-height: initial;
  }
  .cart-col {
    margin-bottom: 3rem;
    overflow: initial;
    max-height: initial;
    border: 2px solid green;
  }
  .even {
    padding: 2rem
  }

}

@media only screen and (max-width: 800px) {
  .cart-col-right {
    width: initial;
  }
  .cart-col {
    width: 75vw;
  }
}
